const TemplateThree = {
  right: {
    primary: {
      size: 4,
      offset: 7,
    },
    firstSupport: {
      size: 2,
      offset: 10,
    },
    secondSupport: {
      size: 3,
      offset: 4,
    },
    thirdSupport: {
      size: 4,
      offset: 0,
    },
  },
  center: {
    primary: {
      size: 5,
      offset: 5,
    },
    firstSupport: {
      size: 3,
      offset: 0,
    },
    secondSupport: {
      size: 4,
      offset: 1,
    },
    thirdSupport: {
      size: 4,
      offset: 9,
    },
  },
  left: {
    primary: {
      size: 4,
      offset: 2,
    },
    firstSupport: {
      size: 3,
      offset: 0,
    },
    secondSupport: {
      size: 2,
      offset: 6,
    },
    thirdSupport: {
      size: 4,
      offset: 8,
    },
  },
}

export default TemplateThree
