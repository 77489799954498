import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Container, Row, Col } from "reactstrap"
import { updateVariantModal } from "../../state/cart"
import Image from "../image"
import TemplateOne from "./templates/TemplateOne"
import TemplateTwo from "./templates/TemplateTwo"
import TemplateThree from "./templates/TemplateThree"
import TemplateFour from "./templates/TemplateFour"

const ShopTemplateRender = ({
  content,
  dispatch,
  products,
  productMetaData,
}) => {
  let template = " template-" + content.positionTemplate
  let position = " position-" + content.layoutPosition
  let handle = content.productHandle

  let currentProduct = products.filter(i => {
    if (i.handle === handle) {
      return i
    }
  })

  let available = currentProduct[0].availableForSale

  let sizesUnavailable = []
  currentProduct[0].variants.map(i => {
    if (!i.availableForSale) {
      sizesUnavailable.push(i.sku.split("-")[1])
    }
  })

  let cta = (
    <div className="button-container">
      <button
        onClick={() =>
          dispatch(
            updateVariantModal({
              isOpen: true,
              productId: currentProduct[0].handle,
              sizesUnavailable: sizesUnavailable,
            })
          )
        }
        className="shop-buy-button"
      >
        Buy Now
      </button>
    </div>
  )

  if (!available) {
    cta = (
      <div className="button-container soldout">
        <button className="shop-buy-button">Sold Out</button>
      </div>
    )
  }

  let grid = {}
  if (content.positionTemplate === "one") {
    grid = TemplateOne
  } else if (content.positionTemplate === "two") {
    grid = TemplateTwo
  } else if (content.positionTemplate === "three") {
    grid = TemplateThree
  } else if (content.positionTemplate === "four") {
    grid = TemplateFour
  }

  let primaryImage,
    primaryImageHover,
    supportImageOne,
    supportImageTwo,
    supportImageThree

  if (content.primaryImage) {
    primaryImage = (
      <Image
        src={content.primaryImage}
        alt="Paris in July"
        className="primary-image"
      />
    )
    if (content.primaryImage.split(".").pop() === "gif") {
      primaryImage = (
        <img
          src={content.primaryImage}
          alt="Paris in July"
          className="primary-image gif"
        />
      )
    }
  }

  if (content.primaryImageHover) {
    primaryImageHover = (
      <Image
        src={content.primaryImageHover}
        alt="Paris in July"
        className="primary-image hover"
      />
    )
    if (content.primaryImageHover.split(".").pop() === "gif") {
      primaryImageHover = (
        <img
          src={content.primaryImageHover}
          alt="Paris in July"
          className="primary-image hover gif"
        />
      )
    }
  }

  if (content.supportImageOne) {
    supportImageOne = (
      <Image
        src={content.supportImageOne}
        alt="Paris in July"
        className="supporting-image one"
      />
    )
    if (content.supportImageOne.split(".").pop() === "gif") {
      supportImageOne = (
        <img
          src={content.supportImageOne}
          alt="Paris in July"
          className="supporting-image one gif"
        />
      )
    }
  }

  if (content.supportImageTwo) {
    supportImageTwo = (
      <Image
        src={content.supportImageTwo}
        alt="Paris in July"
        className="supporting-image two"
      />
    )
    if (content.supportImageTwo.split(".").pop() === "gif") {
      supportImageTwo = (
        <img
          src={content.supportImageTwo}
          alt="Paris in July"
          className="supporting-image two gif"
        />
      )
    }
  }

  if (content.supportImageThree) {
    supportImageThree = (
      <Image
        src={content.supportImageThree}
        alt="Paris in July"
        className="supporting-image three"
      />
    )
    if (content.supportImageThree.split(".").pop() === "gif") {
      supportImageThree = (
        <img
          src={content.supportImageThree}
          alt="Paris in July"
          className="supporting-image three gif"
        />
      )
    }
  }

  return (
    <Row className={"position-relative" + template + position}>
      <Col
        className="position-absolute"
        md={grid[content.layoutPosition].primary}
      >
        <div className="primary-image-container">
          {primaryImage}
          <div className="overlay-container">{primaryImageHover}</div>
          {cta}
        </div>
      </Col>
      <Col
        className="position-absolute"
        md={grid[content.layoutPosition].firstSupport}
      >
        <div className="supporting-image-container one">
          {supportImageOne}
          <div className="overlay-container">
            <p>{content.supportImageOneAttribution}</p>
          </div>
        </div>
      </Col>
      <Col
        className="position-absolute"
        md={grid[content.layoutPosition].secondSupport}
      >
        <div className="supporting-image-container two">
          {supportImageTwo}
          <div className="overlay-container">
            <p>{content.supportImageTwoAttribution}</p>
          </div>
        </div>
      </Col>
      {content.positionTemplate === "two" ? (
        ""
      ) : (
        <Col
          className="position-absolute"
          md={grid[content.layoutPosition].thirdSupport}
        >
          <div className="supporting-image-container three">
            {supportImageThree}
            <div className="overlay-container">
              <p>{content.supportImageThreeAttribution}</p>
            </div>
          </div>
        </Col>
      )}
    </Row>
  )
}
export default connect(
  state => ({
    products: state.products.products,
    productMetaData: state.products.productMetaData,
  }),
  null
)(ShopTemplateRender)
