import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"

import shop_logo from "../../images/shop/shop_logo.png"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import ShopFlow from "../../components/shop/ShopFlow"

import { setOrderModalOpen } from "../../state/cart"

const Shop = ({ dispatch, orderDetails, mobile }) => {
  let logo = <img src={shop_logo} alt="shop-logo" className="shop-logo" />
  if (mobile) {
    logo = (
      <p>
        shop
        <br />
        paris
        <br />
        in
        <br />
        july
      </p>
    )
  }
  return (
    <Layout>
      <SEO title="Shop" />
      <Container fluid className="shop-body-container">
        <Row className="shop-logo-row d-flex justify-content-center">
          <Col className="shop-logo-container" md={{ size: 12 }}>
            {logo}
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col md={{ size: 12 }}>
            <ShopFlow />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default connect(
  state => ({
    orderDetails: state.cart.orderDetails,
    mobile: state.global.mobile,
  }),
  null
)(Shop)
