const TemplateOne = {
  right: {
    primary: {
      size: 4,
      offset: 8,
    },
    firstSupport: {
      size: 2,
      offset: 6,
    },
    secondSupport: {
      size: 3,
      offset: 3,
    },
    thirdSupport: {
      size: 3,
      offset: 0,
    },
  },
  center: {
    primary: {
      size: 4,
      offset: 4,
    },
    firstSupport: {
      size: 2,
      offset: 8,
    },
    secondSupport: {
      size: 3,
      offset: 9,
    },
    thirdSupport: {
      size: 3,
      offset: 0,
    },
  },
  left: {
    primary: {
      size: 4,
      offset: 0,
    },
    firstSupport: {
      size: 2,
      offset: 4,
    },
    secondSupport: {
      size: 3,
      offset: 6,
    },
    thirdSupport: {
      size: 3,
      offset: 9,
    },
  },
}

export default TemplateOne
