import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Container, Row, Col } from "reactstrap"
import { updateVariantModal } from "../../state/cart"
import Image from "../image"

const LayoutFourFlow = ({ content, dispatch }) => {
  let template = " template-" + content.positionTemplate
  let position = " position-" + content.layoutPosition
  let image_grid

  if (content.layoutPosition === "right") {
    image_grid = {
      primary: {
        size: 4,
        offset: 8,
      },
      firstSupport: {
        size: 3,
        offset: 5,
      },
      secondSupport: {
        size: 4,
        offset: 1,
      },
      thirdSupport: {
        size: 2,
        offset: 2,
      },
    }
  }

  if (content.layoutPosition === "center") {
    image_grid = {
      primary: {
        size: 4,
        offset: 5,
      },
      firstSupport: {
        size: 3,
        offset: 9,
      },
      secondSupport: {
        size: 4,
        offset: 1,
      },
      thirdSupport: {
        size: 2,
        offset: 0,
      },
    }
  }

  if (content.layoutPosition === "left") {
    image_grid = {
      primary: {
        size: 4,
        offset: 0,
      },
      firstSupport: {
        size: 4,
        offset: 3,
      },
      secondSupport: {
        size: 4,
        offset: 8,
      },
      thirdSupport: {
        size: 2,
        offset: 9,
      },
    }
  }

  let primaryImage = (
    <Image
      src={content.primaryImage}
      alt="Paris in July"
      className="primary-image"
    />
  )
  if (content.primaryImage.split(".").pop() === "gif") {
    primaryImage = (
      <img
        src={content.primaryImage}
        alt="Paris in July"
        className="primary-image"
      />
    )
  }

  let primaryImageHover = (
    <Image
      src={content.primaryImageHover}
      alt="Paris in July"
      className="primary-image hover"
    />
  )
  if (content.primaryImageHover.split(".").pop() === "gif") {
    primaryImageHover = (
      <img
        src={content.primaryImageHover}
        alt="Paris in July"
        className="primary-image hover"
      />
    )
  }

  let supportImageOne = (
    <Image
      src={content.supportImageOne}
      alt="Paris in July"
      className="supporting-image one"
    />
  )
  if (content.supportImageOne.split(".").pop() === "gif") {
    supportImageOne = (
      <img
        src={content.supportImageOne}
        alt="Paris in July"
        className="supporting-image one"
      />
    )
  }

  let supportImageTwo = (
    <Image
      src={content.supportImageTwo}
      alt="Paris in July"
      className="supporting-image two"
    />
  )
  if (content.supportImageTwo.split(".").pop() === "gif") {
    supportImageOne = (
      <img
        src={content.supportImageTwo}
        alt="Paris in July"
        className="supporting-image two"
      />
    )
  }

  let supportImageThree = (
    <Image
      src={content.supportImageThree}
      alt="Paris in July"
      className="supporting-image three"
    />
  )
  if (content.supportImageThree.split(".").pop() === "gif") {
    supportImageOne = (
      <img
        src={content.supportImageThree}
        alt="Paris in July"
        className="supporting-image three"
      />
    )
  }

  return (
    <Row className={"position-relative" + template + position}>
      <Col className="position-absolute" md={image_grid.primary}>
        <div className="primary-image-container">
          {primaryImage}
          <div className="overlay-container">{primaryImageHover}</div>
          <div className="button-container">
            <button
              onClick={() =>
                dispatch(
                  updateVariantModal({
                    isOpen: true,
                    productId: content.productId,
                  })
                )
              }
              className="shop-buy-button"
            >
              Buy Now
            </button>
          </div>
        </div>
      </Col>
      <Col className="position-absolute" md={image_grid.firstSupport}>
        <div className="supporting-image-container one">
          {supportImageOne}
          <div className="overlay-container">
            <p>{content.supportImageOneAttribution}</p>
          </div>
        </div>
      </Col>
      <Col className="position-absolute" md={image_grid.secondSupport}>
        <div className="supporting-image-container two">
          {supportImageTwo}
          <div className="overlay-container">
            <p>{content.supportImageTwoAttribution}</p>
          </div>
        </div>
      </Col>
      <Col className="position-absolute" md={image_grid.thirdSupport}>
        <div className="supporting-image-container three">
          {supportImageThree}
          <div className="overlay-container">
            <p>{content.supportImageThreeAttribution}</p>
          </div>
        </div>
      </Col>
    </Row>
  )
}
export default connect(state => ({}), null)(LayoutFourFlow)
