const TemplateTwo = {
  right: {
    primary: {
      size: 4,
      offset: 8,
    },
    firstSupport: {
      size: 2,
      offset: 3,
    },
    secondSupport: {
      size: 3,
      offset: 1,
    },
  },
  center: {
    primary: {
      size: 4,
      offset: 5,
    },
    firstSupport: {
      size: 2,
      offset: 9,
    },
    secondSupport: {
      size: 3,
      offset: 2,
    },
  },
  left: {
    primary: {
      size: 4,
      offset: 0,
    },
    firstSupport: {
      size: 2,
      offset: 8,
    },
    secondSupport: {
      size: 3,
      offset: 8,
    },
  },
}

export default TemplateTwo
