import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { connect } from "react-redux"
import { Container, Row, Col } from "reactstrap"

import LayoutOneFlow from "./LayoutOneFlow"
import LayoutTwoFlow from "./LayoutTwoFlow"
import LayoutThreeFlow from "./LayoutThreeFlow"
import LayoutFourFlow from "./LayoutFourFlow"

import ShopTemplateRender from "./ShopTemplateRender"

const ShopFlow = () => {
  const data = useStaticQuery(graphql`
    query {
      allShopLayoutJson {
        edges {
          node {
            date
            positionTemplate
            layoutPosition
            productHandle
            primaryImage
            primaryImageWidth
            primaryImageHover
            supportImageOne
            supportImageOneAttribution
            supportImageTwo
            supportImageTwoAttribution
            supportImageThree
            supportImageThreeAttribution
          }
        }
      }
    }
  `)
  let shop = []
  let index = Object.keys(data)[0]
  let edges = data[index].edges
  edges.forEach(item => {
    let n = item.node.date
    let day = n.toString().split(" ")[0]
    let month = n.toString().split(" ")[1]
    let year = n.toString().split(" ")[2]
    let dt = new Date(year, month, day)
    item.node.date = dt
    if (item.node.productHandle === "blast-over-crew") {
      item.node.productHandle = "spot-check-crewneck"
    }
    if (item.node.productHandle === "chain-hoodie") {
      item.node.productHandle = "lockup-hoodie"
    }
    shop.push(item.node)
  })
  shop.sort(function(a, b) {
    return b.date - a.date
  })
  let display = shop.map((i, idx) => {
    if (i.positionTemplate) {
      return <ShopTemplateRender content={i} key={idx} />
    } else {
      return ""
    }
  })

  return display
}

export default connect(state => ({}), null)(ShopFlow)
